import { endpoints } from './endpoints';

export const environment = {
    name: 'prod',
    develop: true,
    production: false,
    api: {
        base: 'https://api.dev.ai4eosc.eu/v1',
        endpoints,
    },
    llmApiKey: 'sk-b40a8fc2740d4057b5baa7d0e473ea4b',
};
