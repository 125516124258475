@if (open) {
    <mat-card
        appearance="outlined"
        [ngClass]="{ 'fullscreen-chat-card': expanded, 'chat-card': !expanded }"
        [style.--chat-margin]="chatMargin"
    >
        <mat-card-header>
            <mat-card-title>
                <span class="title">
                    {{ 'CHATBOT.TITLE' | translate }}
                    <p class="beta-warning">BETA</p>
                </span>

                <button
                    mat-mini-fab
                    class="resize-button"
                    (click)="resizeChat()"
                >
                    @if (expanded) {
                        <mat-icon>close_fullscreen</mat-icon>
                    } @else {
                        <mat-icon>open_in_full</mat-icon>
                    }
                </button>
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            @if (chatHistory.messages.length === 0) {
                <div class="empty-chat">
                    {{ 'CHATBOT.GREETING' | translate }}
                </div>
            } @else {
                <div #messagesList class="messages-list">
                    @for (message of chatHistory.messages; track message) {
                        <div
                            class="user-message"
                            [ngClass]="{
                                'user-message': message.role === 'user',
                                'bot-message': message.role === 'assistant',
                            }"
                        >
                            <markdown
                                [data]="message.content"
                                ngPreserveWhitespaces
                                [disableSanitizer]="true"
                            >
                            </markdown>
                        </div>
                    }
                </div>
            }

            @if (isLoading) {
                <div class="typing-loader"></div>
            }

            <div class="user-input">
                <mat-form-field
                    subscriptSizing="dynamic"
                    (keyup.enter)="sendMessage()"
                >
                    <input
                        matInput
                        [placeholder]="
                            'CHATBOT.QUESTION-PLACEHOLDER' | translate
                        "
                        [disabled]="isLoading"
                        [(ngModel)]="message"
                    />
                </mat-form-field>
                <button
                    mat-mini-fab
                    [disabled]="isLoading || isQuestionEmpty()"
                    (click)="sendMessage()"
                >
                    <mat-icon>send</mat-icon>
                </button>
            </div>
        </mat-card-content>
    </mat-card>
}

<button
    mat-fab
    extended
    [ngClass]="{
        'chat-closed': !open,
        'chat-opened': open,
    }"
    (click)="manageChat()"
>
    <mat-icon>smart_toy</mat-icon>
    Ask AI
</button>
